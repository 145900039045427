<script setup lang="ts">
import { computed } from "vue";

interface Props {
  tableName: string;
  id: number;
  fileName: string;
}

const props = defineProps({
  tableName: { type: String, required: true, default: "" },
  id: { required: true },
  fileName: { type: String, required: true },
});

const config = useRuntimeConfig();

const url = computed(() => {
  if (props.fileName.length || props.tableName.length) {
    return `${config.public.fireBangDomainName}/_contents/cms/${props.tableName}/${props.id}/image/${props.fileName}`;
  } else {
    // TODO defaultロゴ
    return require("@/assets/images/logo.png");
  }
});
</script>

<template>
  <img :src="url" />
</template>
