<script setup lang="ts">
import { useFetchData } from "@/composables/useFetchFacetContList";

const { fetchFacetContList } = useFetchData();

const props = defineProps({
  contTitle: { type: String, default: "最新の記事", required: false }
});
</script>

<template>
  <section v-if="fetchFacetContList?.length" class="side-cont">
    <h2>
      <span>{{ contTitle }}</span>
    </h2>
    <ul class="side-cont-list">
      <li v-for="(item, index) in fetchFacetContList" :key="index">
        <a :href="`/cont/${item.urlWord}/`" class="image">
          <OrganismsCDNImg
            :id="item.id"
            :table-name="'image' in item ? item.image?.tableName : ''"
            :file-name="
              'image' in item ? '200x133_' + item.image?.fileName : ''
            "
            width="200"
            height="133"
            alt=""
          />
        </a>
        <div>
          <a
            :id="'category_recommend_' + (index + 1)"
            :href="`/cate/${item.categoryId}/`"
            class="category"
          >
            <span>{{ item.categoryName }}</span>
          </a>
          <a :href="`/cont/${item.urlWord}/`" class="headline">
            <strong>{{ item.headline }}</strong>
          </a>
          <time :datetime="item.modifiedAt.toString()">{{
            $dayjs(item.modifiedAt).format("YYYY/MM/DD")
          }}</time>
        </div>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
.side-cont {
  padding: 0 0 8%;
  h2 {
    margin: 0 4%;
    padding: 0.5em;
    color: #1e86c6;
    background-color: #e6e6e6;
    text-align: center;
    font-weight: bold;
  }
}

.side-cont-list {
  margin: 0 4%;
  padding: 0 0.8em;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;

  li {
    padding: 0.8em 0;
    display: flex;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdcdc;
    }

    .image {
      margin-right: 2.5%;
      width: 40%;
    }

    > div {
      width: 60%;
      display: flex;
      flex-wrap: wrap;

      .category {
        margin-bottom: 3%;
        font-size: px(11);
        background: $colorSubBackground;
        border-radius: 12px;
        padding: 1% 4.2% 2%;
        color: $colorDarkBlue;
        display: block;
        white-space: nowrap;
      }

      .headline {
        margin-bottom: 3%;
        color: $colorDarkBlue;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2;
      }

      time {
        color: #999999;
        font-size: px(11);
        line-height: 1;
      }
    }
  }
}

.side-request-catch {
  padding: 0.5em 0;
  color: #fff;
  font-size: px(19.4);
  background-color: $colorBasicBackground;
  text-align: center;

  b {
    color: #fff800;
  }

  br {
    display: none;
  }
}

.side-request-button {
  padding: 0 4% 5%;

  p {
    padding: 1em 0;
    font-size: px(12.9);
  }
}

@include media(pc) {
  .side-cont {
    padding: 0 0 20px;
    h2 {
      margin: 0;
      font-size: 146%;
    }
  }

  .side-cont-list {
    margin: 0;
    padding: 0 21px;
    border: 1px solid #dcdcdc;

    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid #dcdcdc;
      }

      a:hover {
        opacity: 0.7;
      }

      .image {
        margin-right: 8px;
      }

      > div .headline {
        margin-bottom: 8px;
        font-size: px(14);
      }
    }

    img {
      width: 100px;
      height: 66px;
    }

    li > div .category {
      margin-bottom: 5px;
      padding: 2px 19px 3px;
    }

    a > div {
      time {
        margin: 0 0 1%;
        font-size: px(12.9);
      }

      span {
        font-size: px(14);
      }
    }
  }
  .side-request-catch {
    padding: 10px 0;
    font-size: px(21.9);
    font-weight: 700;

    b {
      font-weight: 700;
    }

    br {
      display: block;
    }
  }

  .side-request-button {
    padding: 0 21px 18px;

    p {
      padding: 20px 0;
      font-size: px(15);
    }

    .button-step1 {
      margin: 0 auto 3%;
      padding: 10px 0;
      width: 100%;

      > {
        span {
          margin: 0 auto;
          padding: 0.2em 0.2em 0.2em;
          width: 2.8em;
          display: block;
          font-size: px(18.9);
        }

        div {
          padding: 0;
          font-size: px(21.9);
          background: none;
        }
      }
    }
  }
}
</style>
