<template>
  <div class="side-another">
    <hr>
    <div class="side-another-link">
      <a id="button-kyousai" href="https://www.zenrosai.coop/kyousai/kasai/lp/campaign-index-3.html?utm_source=Unknown&utm_medium=not_payid&utm_term=&utm_content=&utm_campaign=Kantou&argument=Q6fa96MK&dmai=a656007e4db9da" target="_blank"><img src="~/assets/images/kyousai.png" alt=""></a>
      <a href="https://moneyfix.jp/" target="_blank"><img src="~/assets/images/moneyfix.png" alt="マネーFIX｜人生におけるお金の課題を解決するメディア"></a>
    </div>
    <!-- .side-another -->
  </div>
</template>

<style lang="scss" scoped>
.side-another {
  .side-another-link{
    a{
      display: block;
      box-sizing: border-box;
      width: 92%;
      margin: 0 auto;

      &:first-child{
        border: 1px solid #808080;
        margin: 7.47% auto 4.27%;
        position: relative;

        &::before{
          content: "PR";
          position: absolute;
          top: 0;
          left: 0;
          background: #808080;
          color: #FFF;
          padding: 0.52% 1.74% 0.72% 1.74%;
          font-size: 80%;
          line-height: 14px;
        }
      }

      &:hover{
        opacity: 0.7;
      }
    }
  }
}

@include media(pc) {
  .side-another {
    hr{
      border-top: 1px solid #e6e6e6;
      margin: 24px 0;
    }
    .side-another-link{
      a{
        width: 100%;

        &:first-child{
          margin: 0 0 16px 0;

          &::before{
          padding: 2px 5px;
          font-size: 67%;
          line-height: 12px;
        }
        }
      }
    }
  }
}

</style>
