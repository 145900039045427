<template>
  <div class="side-request">
    <div class="side-request-catch">
      <span>戸建ての火災保険を<br /><b>無料で比較・診断!!</b></span>
    </div>
    <div class="side-request-button">
      <p>
        最大15社、41商品からあなたに最適な補償をご提案します。
      </p>
      <a href="/step/step_init.html" class="button-step1"
        ><span>無料</span>
        <div>見積もりを依頼する</div></a
      >
    </div>
    <!-- .side-request -->
  </div>
</template>

<style lang="scss" scoped>
.button-step1 {
  margin: 0 auto 3%;
  padding: 4.4% 0;
  border-radius: 6px;
  width: 100%;
  display: block;
  color: #fff;
  font-weight: bold;
  background-color: $colorBasicButton;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: 0.1s all;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);

  > {
    span {
      margin: 0 0.3em 0 0;
      padding: 0.2em 0.2em 0.3em;
      display: inline-block;
      color: #f00;
      font-size: px(17);
      font-weight: 700;
      background-color: #ff0;
      line-height: 1;
      vertical-align: middle;
    }

    div {
      padding: 0 8% 0 0;
      display: inline-block;
      color: #fff;
      font-size: px(21);
      font-weight: 700;
      background: url(~/assets/images/arrow_orange_sp.png) no-repeat right
        center;
      background-size: 10% auto;
      vertical-align: middle;
      text-shadow: 2px 2px 0px rgba(241, 90, 36, 0.4);
    }
  }
}

.side-request {
  background-color: #f0faff;
}

.side-request-catch {
  padding: 0.5em 0;
  color: #fff;
  font-size: px(19.4);
  background-color: $colorBasicBackground;
  text-align: center;
  
  b {
    color: #fff800;
  }

  br {
    display: none;
  }
}

.side-request-button {
  padding: 0 4% 5%;

  p {
    padding: 1em 0;
    font-size: px(12.9);
  }
}

@include media(pc) {
  .button-step1 {
    margin: 0 auto 21px;
    padding: 21px 0;
    width: 500px;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    &:hover {
      background-color: #f78c35;
    }

    > {
      span {
        margin: 0 0.5em 0 0;
        padding: 0.2em 0.2em 0.3em;
        font-size: px(19.9);
      }

      div {
        padding: 0 8% 0 0;
        font-size: px(30);
        background: url(~/assets/images/arrow_orange.png) no-repeat right
          center;
        background-size: 28px 28px;
      }
    }
  }

  .side-request-catch {
    padding: 10px 0;
    font-size: px(21.9);
    font-weight: 700;

    b {
      font-weight: 700;
    }

    br {
      display: block!important;
    }
  }

  .side-request-button {
    padding: 0 21px 18px;

    p {
      padding: 20px 0;
      font-size: px(15);
    }

    .button-step1 {
      margin: 0 auto 3%;
      padding: 10px 0;
      width: 100%;

      > {
        span {
          margin: 0 auto;
          padding: 0.2em 0.2em 0.2em;
          width: 2.8em;
          display: block;
          font-size: px(18.9);
        }

        div {
          padding: 0;
          font-size: px(21.9);
          background: none;
        }
      }
    }
  }
}
</style>
