<template>
  <ul class="side-cate-list">
    <!-- APIからv-forで出力すると「選び方」まで出てきたり出力順を自由に変更できないため、静的実装している -->
    <li>
      <a id="category_search_1" class="icon-basic" href="/cate/basic/">
        火災保険の基礎知識
      </a>
    </li>
    <li>
      <a
        id="category_search_2"
        class="icon-jishin-basic"
        href="/cate/jishin-basic/"
      >
        地震保険の基礎知識
      </a>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.side-cate-list {
  margin: 0 4%;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;

  li {
    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdcdc;
    }

    a {
      display: block;
      padding: 4.66% 0 5% 15.16%;
      position: relative;
      background: url(~/assets/images/arrow_right_cate.png) no-repeat right
        6.7% center;
      color: $colorBasicText;
    }
  }
}

.side-cate-list li a {
  &.icon-basic:before {
    content: '';
    background: url(~/assets/images/icon_basic_sp.png) no-repeat;
    background-size: contain;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 21%;
    left: 5%;
  }

  &.icon-jishin-basic:before {
    content: '';
    background: url(~/assets/images/icon_jishin_basic_sp.png) no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 26%;
    left: 5%;
  }
}

@include media(pc) {
  .side-cate-list {
    margin: 0;
    padding: 0 21px;
    border: 1px solid #dcdcdc;

    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid #dcdcdc;
      }

      a {
        padding: 21px 0 21px 31px;
        line-height: 1.53;
        background: url(~/assets/images/arrow_right_cate.png) no-repeat right
          center;

        &:hover {
          opacity: 0.7;
        }

        &.icon-basic:before,
        &.icon-jishin-basic:before {
          width: 25px;
          height: 26px;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &.icon-basic:before {
          background: url(~/assets/images/icon_basic.png) no-repeat;
        }

        &.icon-jishin-basic:before {
          background: url(~/assets/images/icon_jishin_basic.png) no-repeat;
        }
      }
    }
  }
}
</style>
